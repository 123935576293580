/*
 *  App.css
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

.imgTitle {
    width: 100%;
}

.stddlg-outer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.stddlg {
    width: 80%;
    background: #EAEAEA;
    position: relative;
    top: 1rem;
    padding: 3rem;
    overflow: hidden;
}