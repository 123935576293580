/*
 *  Navbar.css
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

.NavbarItems {
    background: linear-gradient(90deg, #f9e300 0%,#f9a200 100%);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    text-align: center;
    width: 100vw;
    justify-content: flex-start;
    margin-right: 2rem;
}

.nav-item{
    color: black;
    text-decoration: none;
    padding: 0.2rem 1rem;
    cursor: pointer;
}

.nav-item:hover{
    background-color: #6d76f7;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars{
    color: #fff;
}

.nav-item-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.nav-submenu-div {
    display: none;
    position: absolute;
    margin-left: 0rem;
    margin-top: 0.2rem;
    z-index: 2;
}

.nav-submenu {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    list-style: none;
    margin-top: 0.2rem;
}

.nav-submenu-item {
    display: block;
    background: #f9a200;
    width: auto;
    color: black;
    text-decoration: none;
    padding: 0.2rem 1rem;
    margin-top: 0.1rem;
    border-radius: 4px;
    cursor: pointer;
}

.nav-submenu-item:hover{
    background-color: #8c96ff;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}


@media screen and (max-width: 800px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #6668f4;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-submenu-div.active {
        position: relative;
        margin-top: 0rem;
    }

    .nav-item {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
        cursor: pointer;
    }

    .nav-item:hover {
        background-color: #7577fa;
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%,20%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-item-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 1.0rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: #fff;
        font-size: 1.5rem;

    }

    .nav-item-mobile:hover {
        background: #fff;
        color: #6568f4;
        transition: 250ms;
    }

}