/*
 *  LoginPage.css
 *
 *  @author Martin Wolf
 *  
 *  (C) 2024 Claus Hansen & Martin Wolf IT-Consulting (www.wolf-itc.de)
 * ***************************************************************************/
.loginfeld
{
    position : relative;
    left     : 0px;
}

.zweitesdiv {
    height: 100px;
    width: 200px;
    background: white;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    border: 1px solid gray;
    padding: 5px;
}